import { FunctionComponent, useState } from "react";
import { QrReader } from "./QrReader";
import { Spinner } from "./Spinner";
import classes from "./Scanner.module.scss";

export const Scanner: FunctionComponent<{ apiUrl: string }> = ({ apiUrl }) => {
    const [state, setState] = useState<{ uid: string, input: string }>({ uid: "", input: "" });

    return (
        <div className={classes.scanner}>
            <div className={classes.section}>
                <input
                    type="text"
                    value={state.input}
                    onChange={(event) => setState({ uid: "", input: event.target.value })}
                    onKeyUp={(event) => {
                        if (event.key === "Enter") {
                            setState({ uid: state.input, input: "" });
                        }
                    }}
                    placeholder="enter uid"
                />
                <div className={classes.text}>- or -</div>
                <QrReader onScanned={value => setState({ uid: value, input: "" })} />
                {state.input &&
                    <button className={classes.btn} onClick={() => setState({ uid: state.input, input: "" })}>Generate</button>
                }
                {state.uid &&
                    <div className={classes.spinner}>
                        <img className={classes["qr-code"]} src={`${apiUrl}/device/init/${state.uid}`} />
                        <Spinner />
                    </div>
                }
            </div>
        </div>
    );
};
