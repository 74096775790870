const contentStartRow = new Uint8Array([13, 10, 13, 10]);
const contentTrailer = [0xFF, 0xD9];

export async function readBlobs(stream: ReadableStream<Uint8Array>, boundary: string, onBlob: (snapshot: Blob) => void, verbose = false) {
    try {
        const boundaryRow = new TextEncoder().encode(`--${boundary}\r\n`);
        const chunks = new Array<Uint8Array>();

        const reader = stream.getReader();
        while (true) {
            const { done, value } = await reader.read();
            if (done) {
                break;
            }

            const isBoundaryStart = checkBoundaryStart(value, boundaryRow);
            if (chunks.length === 0 && !isBoundaryStart) {
                if (verbose) {
                    console.warn("Invalid chunk has beed received.");
                }

                continue;
            }

            if (chunks.length > 0 && isBoundaryStart) {
                const blob = extractBlob(chunks);
                if (blob) {
                    onBlob(blob);
                }
                else if (verbose) {
                    console.warn("Jpeg image could not be extracted.");
                }

                chunks.splice(0, chunks.length);
            }

            chunks.push(value);
        }
    }
    catch (error) {
        if ((error as Error)?.name !== "AbortError") {
            throw error;
        }

        return;
    }
}

function checkBoundaryStart(chunk: Uint8Array, boundaryRow: Uint8Array) {
    if (chunk.length < boundaryRow.length) {
        return false;
    }

    for (let i = 0; i < boundaryRow.length; i++) {
        if (boundaryRow[i] !== chunk[i]) {
            return false;
        }
    }

    return true;
}

function extractBlob(chunks: Array<Uint8Array>) {
    const contentStart = findContentStart(chunks);
    if (!contentStart) {
        return;
    }

    const blob = chunks.slice(contentStart[0]);
    blob[0] = blob[0].slice(contentStart[1]);

    if (checkJpegTrailer(blob)) {
        return new Blob(blob, { type: 'image/jpeg' });
    }
}

function findContentStart(chunks: Array<Uint8Array>) {
    let counter = 0;
    for (let i = 0; i < chunks.length; i++) {
        for (let j = 0; j < chunks[i].length; j++) {
            if (counter === contentStartRow.length) {
                return [i, j];
            }

            if (chunks[i][j] !== contentStartRow[counter]) {
                counter = 0;

                continue;
            }

            counter++;
        }
    }
}

function checkJpegTrailer(chunks: Array<Uint8Array>) {
    let counter = 0;

    for (let i = chunks.length - 1; i >= 0; i--) {
        for (let j = chunks[i].length - 1; j >= 0; j--) {
            if (counter === 0) {
                if (chunks[i][j] === contentTrailer[1]) {
                    counter++;

                    continue;
                }

                break;
            }

            return counter === 1 && chunks[i][j] === contentTrailer[0];
        }
    }
}
