import { FunctionComponent, useEffect } from "react";
import { Html5QrcodeScanner } from "html5-qrcode";
import { Html5QrcodeScannerConfig } from "html5-qrcode/html5-qrcode-scanner";

const elementId = "reader";

export const QrReader: FunctionComponent<{ onScanned: (text: string) => void }> = ({ onScanned }) => {
  useEffect(() => {
    const config: Html5QrcodeScannerConfig = {
      fps: 10,
      qrbox: 250,
      supportedScanTypes: [0],
      formatsToSupport: [0],
      rememberLastUsedCamera: false,
    };

    const html5QrcodeScanner = new Html5QrcodeScanner(
      elementId,
      config,
      false,
    );
    html5QrcodeScanner.render((decodedText, _) => onScanned(decodedText), undefined);

    return () => {
      html5QrcodeScanner.clear();
    };
  }, []);

  return (
    <div id={elementId} className="qr-scanner" style={{ width: "100%" }} />
  );
};
