import { FunctionComponent, useEffect, useState } from "react";
import { HashRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { Configuration, getConfiguration } from "./api";
import { Player } from "./Player";
import { Scanner } from "./Scanner";

export const App: FunctionComponent = () => {
  const [configuration, setConfiguration] = useState<Configuration | undefined>(undefined);

  useEffect(() => {
    const abortController = new AbortController();

    getConfiguration(abortController.signal)
      .then(configuration => setConfiguration(configuration))
      .catch(error => console.error(error));

    return () => abortController.abort();
  }, []);

  return (
    configuration
      ? <HashRouter>
        <Routes>
          <Route path="/" element={<Player apiUrl={configuration.apiUrl} />} />
          <Route path="scanner" element={<Scanner apiUrl={configuration.apiUrl} />} />
        </Routes>
      </HashRouter>
      : <></>
  );
}
