import { FunctionComponent } from "react";
import classes from "./Spinner.module.scss";

export const Spinner: FunctionComponent = () => {
    return (
        <div className={classes.spinner}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}
