import { FunctionComponent, useEffect, useState, useRef } from "react";
import { useOrientation } from "@uidotdev/usehooks";
import useSize from "@react-hook/size";
import { QrReader } from "./QrReader";
import { Spinner } from "./Spinner";
import { DeviceList, listDevices, LiveLink, BatteryState, getLive, getMjpegChunks, getBatteryState } from "./api";
import { readBlobs } from "./MjpegStream";
import classes from "./Player.module.scss";

export const Player: FunctionComponent<{ apiUrl: string }> = ({ apiUrl }) => {
  const [state, setState] = useState<{ uid: string, menuItem: "list" | "edit" }>({ uid: "", menuItem: "list" });

  return (
    <div className={classes.player}>
      <div className={`${classes.section} ${classes.menu}`}>
        <button
          className={`${classes.btn} ${classes.item} ${state.menuItem === "list" ? classes.selected : ""}`}
          onClick={() => setState({ uid: "", menuItem: "list" })}
        >
          Select
        </button>
        <button
          className={`${classes.btn} ${classes.item} ${state.menuItem === "edit" ? classes.selected : ""}`}
          onClick={() => setState({ uid: "", menuItem: "edit" })}
        >
          Input or QR
        </button>
      </div>
      {state.menuItem === "list" && (
        <ListSection
          apiUrl={apiUrl}
          uid={state.uid}
          onSelect={uid => setState({ ...state, uid: uid })}
        />
      )}
      {state.menuItem === "edit" && <EditSection uid={state.uid} onSelect={uid => setState({ ...state, uid: uid })} />}
      {state.menuItem === "edit" && state.uid && <ImgPlayer apiUrl={apiUrl} uid={state.uid} />}
    </div>
  );
};

const ListSection: FunctionComponent<{
  apiUrl: string;
  uid: string | undefined;
  onSelect: (uid: string) => void;
}> = ({ apiUrl, uid, onSelect }) => {
  const [devices, setDevices] = useState<DeviceList | undefined>();
  const [liveLinks, setLiveLinks] = useState<{ [name: string]: LiveLink | undefined }>({});
  const [batteryStates, setBatteryStates] = useState<{ [name: string]: BatteryState | undefined }>({});
  const [play, setPlay] = useState<{ uid: string, mediaType: "rtsp" | "http" }>();

  const onClose = (error?: Error) => {
    setPlay(undefined);

    if (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    listDevices(apiUrl, abortController.signal)
      .then((devices) => setDevices(devices))
      .catch((error) => console.error(error));

    return () => abortController.abort();
  }, [apiUrl]);

  const onLiveClick = (uid: string) => {
    setLiveLinks(prevLiveLinks => {
      const liveLinks = { ...prevLiveLinks };
      liveLinks[uid] = undefined;
      return liveLinks;
    });

    getLive(apiUrl, uid, undefined)
      .then(live => {
        if (live) {
          setLiveLinks(prevLiveLinks => {
            const liveLinks = { ...prevLiveLinks };
            liveLinks[uid] = live;
            return liveLinks;
          });
        }
      })
      .catch(error => {
        const newLiveLinks = { ...liveLinks };
        delete newLiveLinks[uid];
        setLiveLinks(newLiveLinks);

        console.error(error);
      });
  }

  const onBatteryStateClick = (uid: string) => {
    setBatteryStates(prevBatteryStates => {
      const batteryStates = { ...prevBatteryStates };
      batteryStates[uid] = undefined;
      return batteryStates;
    });

    getBatteryState(apiUrl, uid, undefined)
      .then(batteryState => {
        if (batteryState) {
          setBatteryStates(prevBatteryStates => {
            const batteryStates = { ...prevBatteryStates };
            batteryStates[uid] = batteryState;
            return batteryStates;
          });
        }
      })
      .catch(error => {
        const newBatteryStates = { ...batteryStates };
        delete newBatteryStates[uid];
        setBatteryStates(newBatteryStates);

        console.error(error);
      });
  }

  const onItemClick = (uid: string) => {
    if (!batteryStates[uid]) {
      onBatteryStateClick(uid);
    }

    onSelect(uid);
  }

  const getBatteryStateText = (uid: string) => {
    const batteryState = batteryStates[uid];

    if (batteryState) {
      return `${batteryState.percent}% ${batteryState.chargeStatus}`;
    }
  }

  return (
    <>
      <div className={`${classes.section} ${classes.list}`}>
        {devices?.items.map((x, i) => (
          <div key={x.uid} className={classes.item}>
            <button
              className={`${classes.btn} ${x.uid === uid ? classes.selected : ""}`}
              onClick={() => onItemClick(x.uid)}
            >
              {x.uid}
            </button>
            {
              x.uid === uid &&
              <div className={classes.actions}>
                <a className={classes.link} onClick={() => setPlay({ uid: x.uid, mediaType: "rtsp" })}>Play RTSP</a>
                <a className={classes.link} onClick={() => setPlay({ uid: x.uid, mediaType: "http" })}>Play HTTP</a>
                <a className={classes.link} onClick={() => navigator.clipboard.writeText(x.uid)}>Copy uid</a>
                <a className={classes.link} onClick={() => onLiveClick(x.uid)}>RTSP link</a>
                <a className={classes.link} onClick={() => onBatteryStateClick(x.uid)}>Battery</a>
              </div>
            }
            {
              x.uid === uid && (uid in batteryStates) &&
              <div className={classes.content}>{getBatteryStateText(uid) ?? <i>loading...</i>}</div>
            }
            {
              x.uid === uid && (uid in liveLinks) &&
              <div className={classes.content}>{liveLinks[uid]?.playURL ?? <i>loading...</i>}</div>
            }
          </div>
        ))}
      </div>
      {play && <ImgContainer apiUrl={apiUrl} uid={play.uid} mediaType={play.mediaType} onClose={onClose} />}
    </>
  );
};

const EditSection: FunctionComponent<{
  uid?: string;
  onSelect: (uid: string) => void;
}> = ({ uid, onSelect }) => {
  return (
    <div className={`${classes.section} ${classes.edit}`}>
      <input
        type="text"
        value={uid}
        onChange={(event) => onSelect(event.target.value)}
        placeholder="enter uid"
      />
      <div className={classes.text}>- or -</div>
      <QrReader onScanned={onSelect} />
    </div>
  );
};

const ImgPlayer: FunctionComponent<{
  apiUrl: string;
  uid: string;
}> = ({ apiUrl, uid }) => {
  const [mediaType, setMediaType] = useState<"rtsp" | "http">("rtsp");
  const [playing, setPlaying] = useState(false);

  const onClose = (error?: Error) => {
    setPlaying(false);

    if (error) {
      console.error(error);
    }
  }

  return (
    <>
      <div className={`${classes.section} ${classes.play}`}>
        <button
          className={`${classes.btn} ${classes.item} ${mediaType === "rtsp" ? classes.selected : ""}`}
          onClick={() => setMediaType("rtsp")}
        >
          RTSP
        </button>
        <button
          className={`${classes.btn} ${classes.item} ${mediaType === "http" ? classes.selected : ""}`}
          onClick={() => setMediaType("http")}
        >
          HTTP
        </button>
        <button
          className={`${classes.btn} ${classes.item} ${playing ? classes.selected : ""}`}
          onClick={() => setPlaying(!playing)}
        >
          Play
        </button>
      </div>
      {playing && <ImgContainer apiUrl={apiUrl} uid={uid} mediaType={mediaType} onClose={onClose} />}
    </>
  );
};

const ImgContainer: FunctionComponent<{
  apiUrl: string;
  uid: string;
  mediaType: "rtsp" | "http";
  onClose: (error?: Error) => void;
}> = ({ apiUrl, uid, mediaType, onClose }) => {
  const orientation = useOrientation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, containerHeight] = useSize(containerRef);
  const [snapshot, setSnapshot] = useState<string | undefined>();

  const onBlob = (blob: Blob) => {
    const url = URL.createObjectURL(blob);

    setSnapshot(prevSnapshot => {
      if (prevSnapshot) {
        URL.revokeObjectURL(prevSnapshot);
      }

      return url;
    })
  }

  const imgStyle = orientation.type.startsWith("portrait")
    ? { left: containerWidth + "px", width: containerHeight + "px", height: containerWidth + "px" }
    : {};

  const imgClassName = orientation.type.startsWith("portrait")
    ? classes.portrait
    : "";

  useEffect(() => {
    const abortController = new AbortController();

    getMjpegChunks(apiUrl, uid, mediaType, abortController.signal)
      .then(chunks => {
        if (chunks) {
          readBlobs(
            chunks.stream,
            chunks.boundary,
            blob => onBlob(blob),
            true)
            .then(() => onClose())
            .catch(error => onClose(error));
        }
      })
      .catch(error => onClose(error));

    return () => {
      abortController.abort();

      if (snapshot) {
        URL.revokeObjectURL(snapshot);
      }
    };
  }, [apiUrl, uid, mediaType]);

  return (
    <div ref={containerRef} className={classes["img-container"]}>
      <button className={classes["close-btn"]} onClick={() => onClose()}>
        X
      </button>
      <Spinner />
      {snapshot && <img className={imgClassName} style={imgStyle} src={snapshot} alt="" />}
    </div>
  );
}
